@import './main.scss';

.footer{
    background:$page-background; 
    font-family:var(--primary-text-font); 
    box-shadow:0px 0px 10px var(--primary-hover-color);
    position:absolute;
    padding:5px;
    bottom: 0;
    width: 100%;
    text-align: center;
    z-index:999;
}
.placeholder-footer{
    width: 100%;
    padding: calc(5px + 2px);
}
