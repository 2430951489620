@import './main';
.test_class{
    color: $primary-color;
    background-color: $primary-background;
}
.app-container{
    background-color:#ffffff!important; 
    height:100vh;
    width: 100vw;
    overflow-y: auto;
    .placeholder{
        height: 120px;
    }
    .content{
        .display-shelf{
            width:100%;
        }
    }
}
.tortinental-nav{
    background-color:rgba(#ffffff,1)!important;
    transition: all 1s;
    *{
    transition: all 1s;
    }
    position: absolute !important;
    z-index:100;
    width: 100vw;
    min-height: 48px;
    &:hover{
        background-color:rgba($primary-color,1)!important;
        box-shadow:0px 3px 0px rgba($primary-background,0.8);
        .title-text{
            color: #ffffff;
        }
    }
    &.scrolled-up{
        background-color:rgba($secondary-color,0.7)!important;
        .title-text{
            font-size:28px;
            color: #ffffff;
            img{
                height:34px;
                width:auto;
            }
        }
    }
    .title-text{
        font-family: var(--primary-text-font);
        font-size: 50px;
        color: $primary-color;
        img{
            height:72px;
            width: auto;
            border-radius:7px;
            box-shadow:1px 2px rgba($tint-color,0.9);
        }
    }
}
