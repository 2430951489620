@import 'main.scss';
@import 'app.scss';
.show-container{
    flex: 0 0 25%;
    max-width: 22%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 5%;
    max-height: 80%;

    .card{
      border-color:transparent;
      transition:all 0.5s ease;
    }
    .card-footer {
        background: transparent;
        border-top: transparent;
        transition:all 0.5s ease;
        font-family:var(--primary-text-font)

    }

  .card {
    &:hover {
        border: 0.044rem solid rgba(100,100,0,0.2);
        box-shadow:1px 1px 10px var(--primary-hover-color);
        opacity:0.7;
    }
  }
  .card {
      .ButtonContainer{
            &:hover {
               color: white;
               opacity: 0.75;
           }
        }
      }
    .card-footer {
        &:hover {
            opacity :1;
        }
    }
.card-img-top {
  position: relative;
  overflow: hidden;
  height:280px;
  width: 100%;
}



.ButtonContainer {
        text-transform: capitalize;
        font-size:1.4rem;
        position: absolute;
        top: 0;
        left:0%;
        transition:all 1s linear;
        opacity: 0;
        color: black;
        font-size: 16px;
        background-color:var(--primary-hover-color);
        padding: 12px 24px;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        font-family: var(--button-text-font);
        width: 100%;
        height:100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
